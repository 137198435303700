# Rails basics
require('@rails/ujs').start()
require('turbolinks').start()

# UiKit
import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'
UiKit.use UiIcons

# Sweet Alert
import sweetAlert from 'sweetalert2/dist/sweetalert2.min'
window.Swal = sweetAlert;

